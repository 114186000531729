<template>
  <div v-if="invoice">
    <b-row class="" v-if="invoice">

        <b-col xl="6" lg="6" md="12">
        <b-card
          class="card-transaction"
          no-body
          style="margin: 0px !important; height: 100% !important"
        >
          <b-card-body>
            <div class="transaction-item mt-1">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="42" variant="light-secondary">
                    <feather-icon size="18" icon="CodesandboxIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h4 class="transaction-title font-weight-bolder">
                    {{ $t("seller_industry") }}
                    <!--<feather-icon  v-b-tooltip.hover-->
                        <!--:title="$t('seller_industry_tooltip')" icon="HelpCircleIcon" size="18" />-->
                  </h4>
                  <h6>
                    <span v-if="companyDescription(invoice.company)">
                      {{
                        companyDescription(invoice.company)
                      }}
                    </span>
                    <span
                      v-else-if="
                        invoice.company &&
                        invoice.company.credit_reform &&
                        invoice.company.credit_reform[0]&&
                        invoice.company.credit_reform[0].industry
                       "
                    >
                    <span v-if="isJsonString(invoice.company.credit_reform[0].industry)">
                  {{ JSON.parse(invoice.company.credit_reform[0].industry)[$store.state.app.language] }}
                </span>
                <span v-else>
                  {{ invoice.company.credit_reform[0].industry }}
                </span>
                    </span>
                  </h6>
                </b-media-body>
              </b-media>
            </div>
            <hr />

            <div class="transaction-item mt-2">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="42" variant="light-primary">
                    <feather-icon size="18" icon="CodesandboxIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h4 class="transaction-title font-weight-bolder">
                    {{ invoice.is_reverse == 1 ? $t("supplier_industry") : $t("debtor_industry") }}
                    <!--<feather-icon  v-b-tooltip.hover :title="$t('debtor_industry_tooltip')"  icon="HelpCircleIcon" size="18" />-->
                  </h4>

                <span v-if="invoice.debtor && companyDescription(invoice.debtor)">
                    {{
                      companyDescription(invoice.debtor)
                    }}
                </span>

                <h6 v-else-if="invoice.debtor &&
                          invoice.debtor.credit_reform &&
                          invoice.debtor.credit_reform[0] &&
                          invoice.debtor.credit_reform[0].industry">
                    <span v-if="isJsonString(invoice.debtor.credit_reform[0].industry)">
                        {{ JSON.parse(invoice.debtor.credit_reform[0].industry)[$store.state.app.language] }}
                    </span>
                    <span v-else>
                      {{ invoice.debtor.credit_reform[0].industry }}
                    </span>
                  </h6>
                </b-media-body>
              </b-media>
            </div>
            <hr />
            <div class="transaction-item mt-2">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="42" variant="light-warning">
                    <feather-icon  size="18" icon="MonitorIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h4 class="transaction-title font-weight-bolder">
                    {{ $t("payment_term_in_days") }}
                    <!--<feather-icon v-b-tooltip.hover :title="$t('payment_terms_in_days_tooltip')"  icon="HelpCircleIcon" size="18" />-->
                  </h4>

                  <h6>
                    {{ invoice.terms }}
                  </h6>
                </b-media-body>
              </b-media>
            </div>
            <hr />
            <div class="transaction-item mt-2">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="42" variant="light-danger">
                    <feather-icon size="18" icon="CalendarIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h4 class="transaction-title font-weight-bolder">
                    {{ $t("due_date") }}
                    <!--<feather-icon v-b-tooltip.hover :title="$t('due_date_tooltip')" icon="HelpCircleIcon" size="18" />-->
                  </h4>

                  <h6>
                    {{ invoice.due_date | formatDate }}
                  </h6>
                </b-media-body>
              </b-media>
            </div>
            <hr />
            <div class="transaction-item mt-2">
              <b-media no-body>
                <b-media-aside>
                  <b-avatar rounded size="42" variant="light-danger">
                    <feather-icon size="18" icon="CalendarIcon" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <h4 class="transaction-title font-weight-bolder">
                    {{ $t("financing_due_in_days") }}
                    <!--<feather-icon v-b-tooltip.hover :title="$t('financing_due_days__tooltip')" icon="HelpCircleIcon" size="18" />-->
                  </h4>

                  <h6>
                    {{ dueDaysLeft(invoice.due_date) }}
                  </h6>
                </b-media-body>
              </b-media>
            </div>
            <!--<hr />-->
            <!--<div class="transaction-item mt-2">-->
              <!--<b-media no-body>-->
                <!--<b-media-aside>-->
                  <!--<b-avatar rounded size="42" variant="light-primary">-->
                    <!--<feather-icon size="18" icon="" />-->
                  <!--</b-avatar>-->
                <!--</b-media-aside>-->
                <!--<b-media-body>-->
                  <!--<h4 class="transaction-title font-weight-bolder">-->
                    <!--{{ $t("invoice_amount") }}-->
                    <!--<feather-icon  v-b-tooltip.hover :title="$t('invoice_amount_tooltip')" icon="HelpCircleIcon" size="18" />-->
                  <!--</h4>-->

                  <!--<h6>-->
                    <!--{{ invoice.amount | formatAmount }}-->
                  <!--</h6>-->
                <!--</b-media-body>-->
              <!--</b-media>-->
            <!--</div>-->
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        xl="6"
        lg="6"
        md="12"
        class="pr-0 pl-0"
        style="margin: 0px !important; height: 100% !important"
      >
        <b-col md="12" style="margin: 0px !important; height: 100% !important">
          <b-card class="p-2 mb-xl-0 mb-lg-0 mb-md-1" no-body>
            <h3 class="font-weight-bolder mb-0 pb-0">
              {{ $t("rating") }}
              <feather-icon icon="HelpCircleIcon" size="18" v-b-tooltip.hover :title="$t('rating_tooltip')"/>
              <br />
              <!-- <h6 class="" v-if="ratingStatus">
                {{ $t(ratingStatus) }}
              </h6> -->
            </h3>

            <div class="row justify-content-center mt-0 pt-0">
              <div
                class=""
                align="center"
                v-if="statistics && statistics.series && statistics.series[0]"
              >
                <vue-apex-charts
                  type="radialBar"
                  height="340"
                  :options="statistics.chartOptions"
                  :series="statistics.series"
                />
              </div>
            </div>
            <hr />
            <div>
              <div class="row mt-1">
                <div class="col-xl-6 col-lg-6">
                  <h4 class="transaction-title font-weight-bolder">
                    {{ $t("roi_in_%") }}
                    <!--<feather-icon icon="HelpCircleIcon" v-b-tooltip.hover :title="$t('discount_in_percentage_tooltip')" size="18" />-->
                  </h4>
                  <span
                    v-if="invoice && invoice.scorings && invoice.scorings[0]"
                  >
                    {{ calculateRoiPercentage(invoice) }}</span>
                    %
                </div>
                <div class="col-xl-6 col-lg-6">
                  <h4 class="transaction-title font-weight-bolder">
                    {{ $t("roi") }}
                    <!--<feather-icon icon="HelpCircleIcon" v-b-tooltip.hover :title="$t('discount_tooltip')" size="18" />-->
                  </h4>
                  <span
                    v-if="invoice && invoice.scorings && invoice.scorings[0]"
                  >
                    <!--{{ invoice.currency.name }}-->
                    {{ amountFormat(calcualteNetReturn(), invoice.currency.name) }}
                    <!--{{ amountFormat((invoice.scorings[0].amount_with_discount / 100) * (invoice.scorings[0].discount_in_percentage - invoice.scorings[0].fee_hive), invoice.currency.name) }}-->
                  </span>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-xl-6 col-lg-6">
                  <h4 class="transaction-title font-weight-bolder">
                    {{ $t("investment_amount") }}
                  </h4>

                  <span
                    v-if="invoice && invoice.scorings && invoice.scorings[0]"
                  >
                    {{ amountFormat(invoice.scorings[0].amount_with_discount, invoice.currency.name) }}
                  </span>

                  <!-- {{invoice.investment_amount}} -->
                </div>
                <div class="col-xl-4 col-lg-6">
                  <h4 class="transaction-title font-weight-bolder">
                    {{ $t("funding_status") }}
                  </h4>
                  <b-progress
                    variant="success"
                    show-progress
                    :value="funding_status"
                    show-value
                    max="100"
                    height="20px"
                    format="percent"
                    style="width: 100% !important;"
                  />
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-col>

    </b-row>

    <b-row v-if="!invoice">
      <img src="/new-loader.svg" />
    </b-row>
  </div>
</template>
<script>
import i18n from "@/libs/i18n";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { CurrencyAmountFormat } from '@core/utils/CurrencyAmountFormat';

export default {
  components: { VueApexCharts },
  data() {
    return {
      data1: [
        {
          text: "market",
          des: "switzerland",
          icon: "GlobeIcon",
        },
        {
          text: "assets_class",
          des: "Invoice Receivables",
          icon: "GlobeIcon",
        },

        {
          text: "currency",
          des: "CHF",
          icon: "DollarSignIcon",
        },
        {
          text: "factoring_type",
          des: "Non-Recourse, Recourse",
        },
        {
          text: "proof_of_ownership",
          des: "Yes, Alternative",
        },
        {
          text: i18n.t("factoring_type"),
          des: i18n.t("Non-Recourse, Recourse"),
        },
        {
          text: i18n.t("sales"),
          des: i18n.t("Regular, Flas"),
        },
        {
          text: i18n.t("collateral(1)"),
          des: i18n.t("intermediar"),
        },
        {
          text: i18n.t("collateral_value_remaining"),
          des: i18n.t("120%"),
        },
      ],
      data: [
        {
          text: i18n.t("seller_industry"),
          des: i18n.t("construction_reconstruction"),
          icon: "CodesandboxIcon",
          color: "secondary",
        },
        {
          text: i18n.t("debtor_industry"),
          des: i18n.t("softwere_development"),
          icon: "CodesandboxIcon",
          color: "secondary",
        },
        {
          text: i18n.t("payment_term_in_days"),
          des: "30" + i18n.t("days"),
          icon: "ClockIcon",
          color: "light-warning",
        },
        {
          text: i18n.t("due_date"),
          des: "12.08.2022",
          icon: "CalendarIcon",
          color: "light-danger",
        },
        {
          text: i18n.t("invoice_amount"),
          des: "12'750.45",
          icon: "",
          color: "light-primary",
        },
      ],
      funding_status: '',
      fractions: [
        {
          fraction: 10,
        },
        {
          fraction: 5,
        },
        {
          fraction: 2,
        },
      ],
      goal_overview: {},
      series: [
        {
          name: "Vue Chart",
          data: [30, 40, 45, 50, 49, 60, 70, 81],
        },
      ],
      load: false,
    };
  },
  props: ["invoice", "statistics", "ratingStatus"],
  watch: {
    invoice: {
      handler(newInvoice) {
        if (newInvoice && newInvoice.status === 10) {
          setTimeout(() => {
            this.funding_status = 100;
          }, 1000);
        }
      },
      immediate: true,
    },
  },

  methods: {


    calcualteNetReturn() {
      let scoring = this.invoice.scorings[0]
      // let net_return = scoring.discount_from_amount
      let hive_fee = scoring.amount * scoring.fee_hive / 100
      let net_return = scoring.discount_from_amount - hive_fee

      return net_return
    },

    amountFormat(amount, currency) {
      return CurrencyAmountFormat(amount, currency)
    },



    companyDescription(company) {

      let company_description = company.description;

      if(company_description == null) {
        return false;
      }


      if(!this.isJsonString(company_description)) {
        return false;
      }

      company_description = JSON.parse(company_description);
      if (company_description && company_description.hasOwnProperty(this.$store.state.app.language)) {
        console.log(company_description[this.$store.state.app.language]);
        return company_description[this.$store.state.app.language];
      }

      return false;
    },
    isJsonString(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    },
    investmentAmount(amount, discount) {
      let res = Number(amount) - Number(discount);
      res =
        "CHF " +
        Number(res)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&'");
      return res;
    },
    discount(amount, discount) {
      let res = Number(amount) * Number(discount);

      res =
        "CHF " +
        Number(res)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&'");
      return res;
    },
  },
  computed: {
    fundingStatus() {
     if (
        this.invoice != null &&
        this.invoice.fractions &&
        this.invoice.fractions.length > 0
      ) {
        this.invoice.fractions.forEach((el) => {
          this.funding_status += el.fraction;
        });
      }

      return this.funding_status;
    },
  },
  created() {
    this.load = true;
    if (this.invoice) {
      this.load = false;
    }
  },
};
</script>
<style scoped>

</style>
